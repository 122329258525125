<template>
	<div class="container">
		<Header  :flag="flag" />
		<div class="banner">
			<div class="banner-content">
				<span class="banner-text">
				 加入我们
				 </span>	
				 <br/>
				<span class="banner-subtitle-text">
				加入蓝莓科技，和我们一起改变世界
				</span>	
			</div>
		</div>
		<div class="team-text">
			<div class="team-us">
				<div class="team-icon-div"></div>
				<div class="team-content">
					创新、正直、团结、敢拼，是蓝莓科技每位成员的缩影。我们信仰简单而坚定，彼此信任且尊重。在蓝莓，不问出处，每个人都是自己的老大。我们强调高效专注，在各自的领域里快速成长，创造价值。
				</div>
				<div class="team-sign-text">
					——蓝莓科技
				</div>
			</div>
		</div>
		<div class="team-piture">
			<div class="team-piture-container">
				<div></div>
				<div class="photos">
					<div class="photos-l">
						<img src="../assets/image/team_01.jpg" alt="蓝莓科技" width="630" height="500"/>
					</div>
					<div class="photos-r">
						<div class="photos-r-top">
							<img src="../assets/image/team_03.jpg" alt="蓝莓科技" width="270" height="199"/>
							<img src="../assets/image/team_04.jpg" alt="蓝莓科技" width="270" height="199"/>
						</div>
						<div class="photos-r-buttom">
							<img src="../assets/image/team_02.jpg" alt="蓝莓科技" width="550" height="280"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="provide">
			<div class="provide-container">
				<div class="provide-title">蓝莓科技为您提供</div>
				<div class="middle-container">
					<div class="container-l">
						<div class="container-l-title">
							成长发展平台
						</div>
						<div class="container-l-desc">
						    人才是蓝莓科技最宝贵的财富，我们的业绩一直保持增长。蓝莓科技为员工提供了富有竞争力的晋升体系，引进线上培训平台，并制定了蔚蓝计划和青蓝计划等系列培训项目，让员工的能力得到更聚焦更清晰的发展。
						</div>
					</div>
					<div class="container-m"></div>
					<div class="container-r">
						<div class="container-r-title">
							<div class="container-r-title1">
								福利待遇
							</div>
							<div class="container-r-title2">
								我们提供有竞争力的薪资待遇及完善丰富的福利体系，让每一位员工在探迹都可以放心的展翅翱翔，追逐梦想。
							</div>
						</div>
						<div class="welfare">
							<div class="welfare-item" v-for="item in welfareList">
								<div class="welfare-item-title">
									{{item.title}}
								</div>
								<div class="welfare-item-desc">
									{{item.content}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="offer">
			<div class="offer-container">
				<div class="offer-title">
					开放职位
				</div>
				<div class="offer-subtitle">
					如果你有同样的梦想等待实现，如果你坚信自己拥有推动大数据与人工智能发展的能力，那么，加入我们，一起奔跑着去改变未来
				</div>
				<div class="content">
				<div class="nav-tab">
					<ul class="tabs">
						<li class="tabItem " v-for="(item,index) in typeList" :class="isActive===index?'offer-isActive':''" @click="selectItem(item,index);">{{item.name}}</li>
					</ul>
				</div>
				<div class="offer-list">
					<el-collapse accordion>
					  <el-collapse-item v-for="(item,index) in positionList">
					    <template slot="title">
							<div class="offer-list-top">
								<div class="position">{{item.position}}</div><div class="address">{{item.location}}</div>
							</div>
					    </template>
						<el-divider></el-divider>
					    <div class="job-content" v-html="item.content">
							
						</div>
					  </el-collapse-item>
					  
					</el-collapse>
				</div>
			</div>
			</div>
		</div>
		<div class="buttom-div">
			<span class="pos">没有找到合适的岗位？</span>
			<span class="mail">你可以发简历到：451607561@qq.com</span>
		</div>
		<Footer />	
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		metaInfo: {
			title: '加入蓝莓科技，和我们一起改变世界_蓝莓',
			meta: [
			  {
				name: 'keywords',
				content: ''
			  },
			  {
				name: 'description',
				content: '加入蓝莓科技，和我们一起改变世界'
			  }
			]
		},
		name:'JoinUs',
		components: {
			Header,
			Footer
		},
		data(){
			return{
				isActive:0,
				flag:'offer',
				welfareList: [],
				typeList:[],
				positionList:[]
			}
		},
		created() {
			this.getWelfare();
			this.getPositionType();
		},
		methods:{
			selectItem(data,index){
				this.isActive=index;
				this.getPosition(data.id);
			},
			getWelfare(){
				this.$http.getWelfareList().then(res => {
					if(res.data!=null){
						this.welfareList = res.data;
					}
				})
			},
			getPositionType(){
				this.$http.getPositionTypeList().then(res => {
					if(res.data!=null){
						this.typeList = res.data;
						this.selectItem(this.typeList[0],0);
					}
				})
			},
			getPosition(id){
				this.$http.getPositionList({positionTypeId:id}).then(res => {
					if(res.data!=null){
						this.positionList = res.data;
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.banner{
		 height: 360px;
		 background-image: url(https://file.lanmeihulian.com/SX_BANNER.png);
		 background-repeat: no-repeat;
		 background-size:100% 100%;
		 .banner-content{
			 width: 1200px;
			 margin: auto;
			 .banner-text{
				 display:inline-block;
				 margin-top: 100px;
				 text-align: center;
				 font-size: 60px;
				 font-weight: 600;
				 line-height: 100px;
				 color: white;
			 }
			 .banner-subtitle-text{
				 display:inline-block;
				 text-align: center;
				 font-size: 28px;
				 color: white;
			 }
		 }
	}
	.team-text{
		background-color: #f6f7fa;
		height: 260px;
		display: flex;
		.team-us{
			width: 1100px;
			margin: auto;
			margin-top: 90px;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			justify-content: space-between;
			height: 90px;
			-moz-box-align: end;
			align-items: flex-end;
			position: relative;
			.team-icon-div{
				position: absolute;
				top: -15px;
				left: 0;
				width: 35px;
				height: 35px;
				background-image: url(../assets/img/quotation-marks.png);
				background-repeat: no-repeat;
			}
			.team-content{
				width: 800px;
				color: #131313;
				padding-left: 77px;
				line-height: 36px;
				font-size: 20px;
				font-weight: 300;
			}
			.team-sign-text{
				color: #131313;
				font-size: 24px;
			}
		}
	}
	.team-piture{
		height: auto;
		.team-piture-container{
			width: 1200px;
			margin: auto;
			position: relative;
			.photos{
				position: relative;
				top: 75px;
				height: 500px;
				display: flex;
				justify-content: space-between;
				.photos-l{
					height: 500px;
				}
				.photos-r{
					height: 500px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.photos-r-top{
						display: flex;
						justify-content: space-between;
					}
					.photos-r-buttom{
						height: 280px;
					}
				}
			}	
		}
	}
	.provide{
		background: #f6f7fa;
		height: 950px;
		overflow: hidden;
		.provide-container{
			width: 1200px;
			margin: auto;
			padding-top: 230px;
			height: 100%;
			position: relative;
			.provide-title{
				color: #131313;
				font-size: 32px;
			}
			.middle-container{
				margin-top: 55px;
				display: flex;
				display: -webkit-flex; /* Safari */
				font-size: 16px;
				line-height: 28px;
				color: #217bfb;
				.container-l{
					height: 100%;
					width: 391px;
					padding-left:71px;
					.container-l-title{
						height: 60px;
						font-size: 26px;
						font-weight: 600;
						line-height: 25px;
						color: #217bfb;
					}
					.container-l-desc{
						line-height: 28px;
						color: #555555;
					}
				}
				.container-m{
					background: #9dc8e1;
					height: 100%;
					width: 1px;
					margin: 0 48px 0 80px;
				}
				.container-r{
					padding-left: 80px;
					width: 670px;
					.container-r-title{
						.container-r-title1{
							height: 60px;
							font-size: 26px;
							font-weight: 600;
							line-height: 25px;
							color: #217bfb;
						}
						.container-r-title2{
							line-height: 28px;
							color: #555555;
						}
					}
					.welfare{
						width: 650px;
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;
						.welfare-item{
							margin-top: 49px;
							width: 280px;
							color: #131313;
							.welfare-item-title{
								font-weight: 500;
								font-size: 20px;
							}
							.welfare-item-desc{
								color: #555555;
								margin-top: 12px;
							}
						}
					}
				}
			}
		}
	}
	.offer{
		background: #ffffff;
		padding-top: 99px;
		padding-bottom: 146px;
		.offer-container{
			width: 1200px;
			margin: auto;
			
			.offer-title{
				color: #131313;
				font-size: 32px;
			}
			.offer-subtitle{
				margin-top: 22px;
				font-size: 16px;
				line-height: 28px;
				color: #555555;
			}	
			.content{
				margin-top: 55px;
			.tabs{
				display: flex;
				display: -webkit-flex; /* Safari */
				justify-content: center;
				border: 1px solid #07f;
				.tabItem{
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 86px;
					width: 100%;
					font-size: 20px;
				}
				.offer-isActive{
					background: #07f;
					color: #ffffff;
				}
			}
			.offer-list{
			    margin-top: 41px;	
				.offer-list-top{
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 18px;
					.position{
						color: #07f;
						padding-left: 53.5px;
						width: 550px;
						flex-shrink: 0;
					}
					.address{
						color: #555555;
						width: 100%;
					}
				}
				.job-content{
					font-size: 16px;
					padding-left: 53.5px;
					margin-top: 24px;
				}
			}	
		}
		}
	}
	.buttom-div{
		width: 1200px;
		height: 140px;
		margin: auto;
		position: relative;
		transform: translateY(-50%);
		padding: 0 232px;
		background-color: #007AFF;
		box-sizing: border-box;
		z-index: 10;
		.pos{
			font-size: 32px;
			line-height: 140px;
			color: #fff;
			display: inline-block;
		}
		.mail{
			display: inline-block;
			margin-left: 52px;
			font-size: 16px;
			line-height: 140px;
			color: #fff;
		}
	}	
</style>
