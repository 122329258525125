var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Header',{attrs:{"flag":_vm.flag}}),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"provide"},[_c('div',{staticClass:"provide-container"},[_c('div',{staticClass:"provide-title"},[_vm._v("蓝莓科技为您提供")]),_c('div',{staticClass:"middle-container"},[_vm._m(3),_c('div',{staticClass:"container-m"}),_c('div',{staticClass:"container-r"},[_vm._m(4),_c('div',{staticClass:"welfare"},_vm._l((_vm.welfareList),function(item){return _c('div',{staticClass:"welfare-item"},[_c('div',{staticClass:"welfare-item-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"welfare-item-desc"},[_vm._v(" "+_vm._s(item.content)+" ")])])}),0)])])])]),_c('div',{staticClass:"offer"},[_c('div',{staticClass:"offer-container"},[_c('div',{staticClass:"offer-title"},[_vm._v(" 开放职位 ")]),_c('div',{staticClass:"offer-subtitle"},[_vm._v(" 如果你有同样的梦想等待实现，如果你坚信自己拥有推动大数据与人工智能发展的能力，那么，加入我们，一起奔跑着去改变未来 ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"nav-tab"},[_c('ul',{staticClass:"tabs"},_vm._l((_vm.typeList),function(item,index){return _c('li',{staticClass:"tabItem",class:_vm.isActive===index?'offer-isActive':'',on:{"click":function($event){return _vm.selectItem(item,index);}}},[_vm._v(_vm._s(item.name))])}),0)]),_c('div',{staticClass:"offer-list"},[_c('el-collapse',{attrs:{"accordion":""}},_vm._l((_vm.positionList),function(item,index){return _c('el-collapse-item',[_c('template',{slot:"title"},[_c('div',{staticClass:"offer-list-top"},[_c('div',{staticClass:"position"},[_vm._v(_vm._s(item.position))]),_c('div',{staticClass:"address"},[_vm._v(_vm._s(item.location))])])]),_c('el-divider'),_c('div',{staticClass:"job-content",domProps:{"innerHTML":_vm._s(item.content)}})],2)}),1)],1)])])]),_vm._m(5),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-content"},[_c('span',{staticClass:"banner-text"},[_vm._v(" 加入我们 ")]),_c('br'),_c('span',{staticClass:"banner-subtitle-text"},[_vm._v(" 加入蓝莓科技，和我们一起改变世界 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team-text"},[_c('div',{staticClass:"team-us"},[_c('div',{staticClass:"team-icon-div"}),_c('div',{staticClass:"team-content"},[_vm._v(" 创新、正直、团结、敢拼，是蓝莓科技每位成员的缩影。我们信仰简单而坚定，彼此信任且尊重。在蓝莓，不问出处，每个人都是自己的老大。我们强调高效专注，在各自的领域里快速成长，创造价值。 ")]),_c('div',{staticClass:"team-sign-text"},[_vm._v(" ——蓝莓科技 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team-piture"},[_c('div',{staticClass:"team-piture-container"},[_c('div'),_c('div',{staticClass:"photos"},[_c('div',{staticClass:"photos-l"},[_c('img',{attrs:{"src":require("../assets/image/team_01.jpg"),"alt":"蓝莓科技","width":"630","height":"500"}})]),_c('div',{staticClass:"photos-r"},[_c('div',{staticClass:"photos-r-top"},[_c('img',{attrs:{"src":require("../assets/image/team_03.jpg"),"alt":"蓝莓科技","width":"270","height":"199"}}),_c('img',{attrs:{"src":require("../assets/image/team_04.jpg"),"alt":"蓝莓科技","width":"270","height":"199"}})]),_c('div',{staticClass:"photos-r-buttom"},[_c('img',{attrs:{"src":require("../assets/image/team_02.jpg"),"alt":"蓝莓科技","width":"550","height":"280"}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-l"},[_c('div',{staticClass:"container-l-title"},[_vm._v(" 成长发展平台 ")]),_c('div',{staticClass:"container-l-desc"},[_vm._v(" 人才是蓝莓科技最宝贵的财富，我们的业绩一直保持增长。蓝莓科技为员工提供了富有竞争力的晋升体系，引进线上培训平台，并制定了蔚蓝计划和青蓝计划等系列培训项目，让员工的能力得到更聚焦更清晰的发展。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-r-title"},[_c('div',{staticClass:"container-r-title1"},[_vm._v(" 福利待遇 ")]),_c('div',{staticClass:"container-r-title2"},[_vm._v(" 我们提供有竞争力的薪资待遇及完善丰富的福利体系，让每一位员工在探迹都可以放心的展翅翱翔，追逐梦想。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buttom-div"},[_c('span',{staticClass:"pos"},[_vm._v("没有找到合适的岗位？")]),_c('span',{staticClass:"mail"},[_vm._v("你可以发简历到：451607561@qq.com")])])
}]

export { render, staticRenderFns }